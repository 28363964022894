<template>
  <div style="width: calc(100% - 200px);padding: 10px">
    <div style="margin: 10px 0">
      <el-input v-model="search" placeholder="请输入图纸号" style="width:35%"></el-input>
      <el-button type="primary" style="margin-left:5px" @click = "selectkey">查询</el-button>
      <el-button type="primary" @click="remove">重置</el-button>
    </div>
    <el-table
        :data="tableData"
        border
        style="width: 80%">
      <el-table-column
          prop="tuzhihao"
          label="图纸号"
          fixed="left">
      </el-table-column>
      <el-table-column
          prop="gongxu"
          label="工序"
          fixed="left">>
      </el-table-column>
      <el-table-column
          prop="yuanyin"
          label="废件原因">
      </el-table-column>
      <el-table-column
          prop="num"
          label="废件数量">
      </el-table-column>
      <el-table-column
          prop="beizhu"
          label="备注">
      </el-table-column>
    </el-table>
    <div style="margin: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[5, 10, 20]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>

import request from "@/utils/request";

export default {
  data() {
    return {
      search:'',
      currentPage4:1,
      total:10,
      tableData: []
    }
  },
  //加载表格
  mounted() {
    this.select()
  },
  methods:{
    //整表查询
    select(){
      /*request.post("/connect/select").then(res =>{
        this.tableData = res.data
      })*/
    },
    //关键字查询
    selectkey(){
      if (parseInt(this.search) >0 ){
        request.post("/connect/selectid",parseInt(this.search)).then(res =>{
          this.tableData = res.data
        })
      } else {
        request.post("/connect/selectkey",this.search).then(res =>{
          this.tableData = res.data
        })
      }
    },
    //重置
    remove(){
      this.search=''
      this.select();
    },
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    },
  }
}

</script>

<style scoped>

</style>